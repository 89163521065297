$(document).ready(function () {
  let lang = localStorage.getItem("lang");
  if (lang != null) {
    let path = window.location.pathname;
    let currentLang = path.split("/")[1];
    if (currentLang == undefined) {
      window.location.href = "/" + lang + "/" + destination;
    }
    setTimeout(function () {
      /*if (path.split("/")[1] == "broadcaster") {
        let parameters = window.location.href.split("?")[1];
        window.location.href =
          lang == "fr-ca"
            ? "/" + lang + path + "?" + parameters
            : path + "?" + parameters;
      }*/
      if (path.split("/")[2] == "compte" || path.split("/")[2] == "account") {
        if (currentLang != lang) {
          var destination = lang == "en" ? "account" : "compte";
          window.location.href = "/" + lang + "/" + destination;
        }
      }
    }, 1000);
  }
});
